export class BeneficiarioRecadastramento {

	id?: number;
	categoria?: string;
	dapCaf?: string;
	dataLog?: string;
	entidadeProponente?: number;
	usuario?: number;
	usuarioLog?: number;
	excluido?: boolean;
}