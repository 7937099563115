import { Usuario, NotificacaoHasUsuarioDTO } from '../../core/model';
import { Component } from "@angular/core";
import { Headers, Http, URLSearchParams } from "@angular/http";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { ToastyService } from "ng2-toasty";
import { ReCaptchaV3Service } from 'ngx-captcha';
import { environment } from "../../../environments/environment";
import { ErrorHandlerService } from "../../core/error-handler.service";
import { Cidadao, EntidadeUsuarioAreaPublicaDTO, Osc } from "../../core/model";
import { PessaoUtil } from "../../core/pessoa.util";
import { GetAdress } from '../../core/services/utils/get-address.service';

@Component({
  selector: "app-recadastramento",
  templateUrl: "./recadastramento.component.html",
  styleUrls: ["./recadastramento.component.css"]
})
export class RecadastramentoComponent {
  osc = new Osc();
  cidadao = new Cidadao();
  pessoaUtil = new PessaoUtil();
  
  tiposCadastro = [
    { label: 'Pessoa Física', value: 'PF' },
    { label: 'OSC', value: 'OSC' }
  ];
  tipoCadastroSelecionado: string = '';

  repetirSenha: any;
  reCaptcha: any;
  etapa1: boolean = false;
  etapa2: boolean = false;
  etapa3: boolean = false;
  etapa4: boolean = false;
  esconderEtapa3: boolean = false;

  constructor(
    private http: Http,
    private getAdress: GetAdress,
    private errorHandler: ErrorHandlerService,
    private toasty: ToastyService,
    private router: Router,
    private title: Title,
    private reCaptchaV3Service: ReCaptchaV3Service,
  ) {
    this.title.setTitle("Recadastramento");

    this.reCaptchaV3Service.execute("6LdCbIoUAAAAAJL-BVvqkCR5fhjEg84kUXNS2MOr", "login", token => {
      this.reCaptcha = token;
    });
  }

  selecionarTipoCadastro() {
    if (this.tipoCadastroSelecionado === 'PF') {
      this.osc = new Osc(); // Reset OSC data
      this.openEtapa2();
      this.esconderEtapa3 = false; // Show the third step
    } else {
      this.openEtapa2();
      this.esconderEtapa3 = true; // Hide the third step
    }
  }

  openEtapa1() {
    this.etapa1 = true;
    this.etapa2 = false;
    this.etapa3 = false;
    this.etapa4 = false;
  }

  openEtapa2() {
    this.etapa1 = false;
    this.etapa2 = true;
    this.etapa3 = false;
    this.etapa4 = false;
  }

  openEtapa3() {
    this.etapa1 = false;
    this.etapa2 = false;
    this.etapa3 = true;
    this.etapa4 = false;
  }

  openEtapa4() {
    this.etapa1 = false;
    this.etapa2 = false;
    this.etapa3 = false;
    this.etapa4 = true;
  }

  verificaPreenchimentoGestor() {
    if (this.cidadao.nome == '' || this.cidadao.nome == null) {
      this.toasty.warning("Infome o nome do " + (this.tipoCadastroSelecionado === 'PF' ? 'Usuário' : 'Gestor da Entidade') + "!");
      this.tipoCadastroSelecionado === 'PF' ? this.openEtapa2() : this.openEtapa3();
      return;
    } else if (this.cidadao.cpf == '' || this.cidadao.cpf == null) {
      this.toasty.warning("Infome o CPF do " + (this.tipoCadastroSelecionado === 'PF' ? 'Usuário' : 'Gestor da Entidade') + "!");
      this.tipoCadastroSelecionado === 'PF' ? this.openEtapa2() : this.openEtapa3();
      return;
    } else if (this.cidadao.email == '' || this.cidadao.email == null) {
      this.toasty.warning("Infome o e-mail do " + (this.tipoCadastroSelecionado === 'PF' ? 'Usuário' : 'Gestor da Entidade') + "!");
      this.tipoCadastroSelecionado === 'PF' ? this.openEtapa2() : this.openEtapa3();
      return;
    }else {
      this.salvar();
    }
  }

  salvar() {
    if (this.cidadao.senha != this.repetirSenha) {
      this.toasty.error("As senhas não conferem.");
      return null;
    }
    if (this.tipoCadastroSelecionado === 'PF') {
      this.adicionarPessoaFisica();
    } else {
      this.adicionar();
    }
  }

  adicionarPessoaFisica(): Promise<any> {
    if (this.reCaptcha != null) {
      this.cidadao.perfil = {id:17} //CIDADÃO;
      let url = `${environment.apiUrl}/usuarios/area-publica`;
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      return this.http
        .post(url, JSON.stringify(this.cidadao), { headers })
        .toPromise()
        .then(response => {
          this.toasty.success("Recadastramento realizado com sucesso!");
          this.router.navigate(["/login"]);
        })
        .catch(erro => this.errorHandler.handle(erro));
    } else {
      this.errorHandler.handle("Erro captcha");
    }
  }

  adicionar(): Promise<any> {
    if (this.reCaptcha != null) {

      this.osc.statusHabilitacao = 0
      this.osc.tipoProponente = 'OSC'

      let entidadeUsuarioAreaPublicaDTO = new EntidadeUsuarioAreaPublicaDTO()
      entidadeUsuarioAreaPublicaDTO.entidadeDto = this.osc
      entidadeUsuarioAreaPublicaDTO.usuarioDto = this.cidadao
      let url = `${environment.apiUrl}/entidade-proponente/area-publica`
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      return this.http
        .post(url, JSON.stringify(entidadeUsuarioAreaPublicaDTO), { headers })
        .toPromise()
        .then(response => {
          let data = response.json()
          this.enviarMensagem(data.usuarioDto)
        });


    } else {
      this.errorHandler.handle("Erro capctha");
    }
  }

  async enviarMensagem(usuario) {

    let usuariosLista = [];
    usuariosLista.push(usuario)

    var notificacaoHasUsuarioDTO = new NotificacaoHasUsuarioDTO()

    notificacaoHasUsuarioDTO.dataEnvio = new Date().getTime()
    notificacaoHasUsuarioDTO.statusNotificacao = 'Nao_lida'
    notificacaoHasUsuarioDTO.favorito = 0
    notificacaoHasUsuarioDTO.lixeira = 0
    notificacaoHasUsuarioDTO.usuarioLista = usuariosLista
    notificacaoHasUsuarioDTO.notificacao.id = 1

    let url = `${environment.apiUrl}/notificacao-has-usuario/salvar-notificacoes`
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    return this.http.post(url, JSON.stringify(notificacaoHasUsuarioDTO), { headers })
      .toPromise()
      .then(response =>{
        this.toasty.success("Recadastramento realizado com sucesso");
        this.router.navigate(["/login"]);
      })
      .catch(erro => this.errorHandler.handle(erro));
  }


  getEnderecoCep(event, tipo) {
    let value = event.target.value;
    if (value) {
      this.getAdress
        .getEnderecoCep(value)
        .then(response => {
          let data = response
          if (data.erro) {
            this.toasty.error("CEP inválido.");
            return
          }
          if (tipo === 'osc') {
            this.osc.endereco.cep = data.cep
            this.osc.endereco.endereco = data.logradouro
            this.osc.endereco.complemento = data.complemento
            this.osc.endereco.bairro = data.bairro
            this.osc.endereco.cidade = data.localidade
            this.osc.endereco.estado = data.uf
          }
          if (tipo === 'cidadao') {
            this.cidadao.endereco.cep = data.cep
            this.cidadao.endereco.endereco = data.logradouro
            this.cidadao.endereco.complemento = data.complemento
            this.cidadao.endereco.bairro = data.bairro
            this.cidadao.endereco.cidade = data.localidade
            this.cidadao.endereco.estado = data.uf
          }
        })
        .catch(erro => this.errorHandler.handle(erro));

    }
  }

  // ========== VALIDACAO CPF, EMAIL e CNPJ

  verificarCpf(): Promise<any> {

    if (this.pessoaUtil.vercpf(this.cidadao.cpf) != false) {

      if (this.cidadao.cpf != null && this.cidadao.cpf.length > 10) {

        let url = `${environment.apiUrl}/usuarios/pesquisa-publica`

        if (this.reCaptcha != null) {

          const params = new URLSearchParams();
          if (this.cidadao.cpf) {
            params.set("cpf", this.cidadao.cpf);
          }

          return this.http
            .get(url, { search: params })
            .toPromise()
            .then(response => {
              if (response.status == 200) {
                this.toasty.error("CPF já cadastrado")
                this.cidadao.cpf = null
              }
              // //console.log("Teste: " + response.status)
            });
        } else {
          this.errorHandler.handle("Erro capctha");
        }

      } else {
        this.toasty.warning("Por Favor preencha o CPF !")
      }

    } else {
      this.toasty.info("CPF INVALIDO")
      this.cidadao.cpf = null
    }
  }

  verificarEmail(): Promise<any> {
    this.cidadao.email = this.cidadao.email.toLowerCase();

    if (this.cidadao.email != null && this.cidadao.email.length > 8) {
      let url = `${environment.apiUrl}/usuarios/pesquisa-publica`

      if (this.reCaptcha != null) {
        const params = new URLSearchParams();
        if (this.cidadao.email) {
          params.set("email", this.cidadao.email);
        }
        return this.http
          .get(url, { search: params })
          .toPromise()
          .then(response => {
            if (response.status == 200) {
              this.toasty.error("Email já cadastrado")
              this.cidadao.email = null
            }
          });
      } else {
        this.errorHandler.handle("Erro capctha");
      }
    } else {
      this.toasty.warning("Por favor preencha o email !")
    }

  }

  verificarCnpj() {
    if (this.osc.cnpj != null && this.osc.cnpj.length > 10) {
      if (this.pessoaUtil.validarCNPJ(this.osc.cnpj) != false) {
        let url = `${environment.apiUrl}/entidade-proponente/pesquisa-publica`

        if (this.reCaptcha != null) {

          const params = new URLSearchParams();
          if (this.osc.cnpj) {
            params.set("cnpj", this.osc.cnpj);
          }
          return this.http
            .get(url, { search: params })
            .toPromise()
            .then(response => {
              if (response.status == 200) {
                this.toasty.error("CNPJ já cadastrado")
                this.osc.cnpj = null
              }
            });
        } else {
          this.errorHandler.handle("Erro capctha");
        }
      } else {
        this.osc.cnpj = null
        this.errorHandler.handle("CNPJ inválido!");
      }

    } else {
      this.toasty.warning("Por favor preencha o CNPJ!")
    }
  }

  verificarEmailOsc() {
    this.osc.email = this.osc.email.toLowerCase();
  }

}