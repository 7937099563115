import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ToastyService } from 'ng2-toasty';
import { ErrorHandlerService } from '../../../core/error-handler.service';
import { ComissaoAcompanhamento } from '../../../core/model';
import { ConcedenteService } from '../../../core/services/concedente/concedente.service';
import { AuthService } from '../../../seguranca/auth.service';
import { ComissaoAcompanhamentoService } from '../comissaoAcompanhamento.service';

declare let AdminLTE: any;

@Component({
  selector: 'app-comissao-acompanhamento-cadastro',
  templateUrl: './comissao-acompanhamento-cadastro.component.html'
})
export class ComissaoAcompanhamentoCadastroComponent implements OnInit {

  comissaoAcompanhamento = new ComissaoAcompanhamento();
  entidades = [];
  tiposComissao = [
    { label: 'Convênio', value: 'CONVENIO' },
    { label: 'Parceria', value: 'PARCERIA' },
    { label: 'Subvenção', value: 'SUBVENCAO' }];
  entidade

  constructor(
    public auth: AuthService,
    private comissaoAcompanhamentoService: ComissaoAcompanhamentoService,
    private entidadeConcedenteService: ConcedenteService,
    private errorHandler: ErrorHandlerService,
    private toasty: ToastyService,
    private router: Router,
    private title: Title,
    private location: Location
  ) { }

  ngOnInit() {

    // Update the AdminLTE layouts
    AdminLTE.init();

    this.verificaComissaoEntidade()

    this.title.setTitle('Nova comissão de seleção');
  }

  goBack() {
    this.location.back();
  }

  verificaComissaoEntidade() {
    if (this.auth.jwtPayload.concedente) {
      this.comissaoAcompanhamento.entidadeConcedente.id = this.auth.jwtPayload.concedente;
      this.carregarEntidadesConcedentePreDefnida(this.auth.jwtPayload.concedente)
    } else {
      this.carregarEntidadesConcedente();
    }
  }

  salvar() {
    // Verifica se o campo tipoComissao foi preenchido
    if (!this.comissaoAcompanhamento.tipoComissao) {
      this.toasty.error('Selecione o tipo da comissão antes de salvar.');
      return;
    }
  
    // Continua com o salvamento se os campos obrigatórios estiverem preenchidos
    this.comissaoAcompanhamentoService.adicionar(this.comissaoAcompanhamento)
      .then(() => {
        this.toasty.success('Comissão de acompanhamento adicionada com sucesso!');
        this.router.navigate(['/cadastros/concedente/comissao-acompanhamento']);
      })
      .catch(erro => this.errorHandler.handle(erro));
  }
  

  carregarEntidadesConcedente() {
    return this.entidadeConcedenteService.listarTodos()
      .then(entidades => {
        this.entidades = entidades.map(c => ({ label: c.nome, value: c.id }));
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  carregarEntidadesConcedentePreDefnida(codigo) {
    return this.entidadeConcedenteService.buscarPorCodigo(codigo)
      .then(entidade => {
        this.entidade = entidade;
        ////console.log(this.entidade)
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

}
