import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable()
export class CriptoService {

  constructor() { }

  /**
   * Criptografa e armazena dados no sessionStorage
   * @param value Valor a ser criptografado
   * @param key Chave para criptografia
   * @returns void
   */
  encryptAndStore(value: any, key: string): void {
    try {
      const valueToStore = JSON.stringify(value);
      const encrypted = CryptoJS.AES.encrypt(valueToStore, '5x#K8b!Nv4Qm').toString();
      sessionStorage.setItem(key, encrypted);
    } catch (error) {
      console.error('Erro ao criptografar:', error);
    }
  }

  /**
   * Recupera e descriptografa dados do sessionStorage
   * @param key Chave para descriptografia
   * @returns T | null - Retorna o valor descriptografado ou null em caso de erro
   */
  decryptAndRetrieve<T>(key: string): T | null {
    try {
      const encrypted = sessionStorage.getItem(key);
      if (!encrypted) return null;

      const decrypted = CryptoJS.AES.decrypt(encrypted, '5x#K8b!Nv4Qm');
      const decryptedString = decrypted.toString(CryptoJS.enc.Utf8);
      
      return JSON.parse(decryptedString);
    } catch (error) {
      console.error('Erro ao descriptografar:', error);
      return null;
    }
  }
}