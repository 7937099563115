import { Injectable } from '@angular/core';
import { URLSearchParams } from '@angular/http';
import { AuthHttp } from 'angular2-jwt';
import 'rxjs/add/operator/toPromise';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../seguranca/auth.service';
import { EixoPrograma, Usuario } from '../../model';

export class EixoProgramaFiltro {
  nome: string;
  descricao: string;
  pagina = 0;
 itensPorPagina = 20;
}

@Injectable()
export class EixoProgramaService {

  eixoProgramaUrl: string;
  usuarioAdmin = new Usuario();

  constructor(private http: AuthHttp,
    public auth: AuthService, ) {
    this.eixoProgramaUrl = `${environment.apiUrl}/eixo-programa`;
  }

  pesquisar(filtro: EixoProgramaFiltro): Promise<any> {
    const params = new URLSearchParams();

    params.set('page', filtro.pagina.toString());
    params.set('size', filtro.itensPorPagina.toString());

    if (filtro.nome) {
      params.set('nome', filtro.nome);
    }
    if (filtro.descricao) {
      // //console.log(filtro.descricao)
      params.set('descricao', filtro.descricao);
    }

    return this.http.get(`${this.eixoProgramaUrl}`, { search: params })
      .toPromise()
      .then(response => {
        const responseJson = response.json();
        const dado = responseJson.content;

        const resultado = {
          dado,
          total: responseJson.totalElements
        };
        // //console.log(resultado)
        return resultado;

      })
  }

  listarTodos(): Promise<any> {
    return this.http.get(this.eixoProgramaUrl)
      .toPromise()
      .then(response => response.json().content);
  }

  listarEixosDoEdital(id: any): Promise<any> {
    const params = new URLSearchParams();
    params.set('editalCredenciamento', id);

    return this.http.get(`${this.eixoProgramaUrl}`, { search: params })
      .toPromise()
      .then(response => response.json().content);
  }

  excluir(codigo: number): Promise<void> {
    return this.http.delete(`${this.eixoProgramaUrl}/${codigo}`)
      .toPromise()
      .then(() => null);
  }

  adicionar(eixoPrograma: EixoPrograma): Promise<EixoPrograma> {
    // //console.log(eixoPrograma)
    return this.http.post(this.eixoProgramaUrl, JSON.stringify(eixoPrograma))
      .toPromise()
      .then(response => response.json());
  }

  atualizar(eixoPrograma: EixoPrograma): Promise<EixoPrograma> {
    return this.http.put(`${this.eixoProgramaUrl}/${eixoPrograma.id}`,
      JSON.stringify(eixoPrograma))
      .toPromise()
      .then(response => response.json() as EixoPrograma);
  }

  buscarPorCodigo(codigo: number): Promise<EixoPrograma> {
    return this.http.get(`${this.eixoProgramaUrl}/${codigo}`)
      .toPromise()
      .then(response => {
        const eixoPrograma = response.json() as EixoPrograma;
        return eixoPrograma;
      });
  }

  buscarEixoCompleto(codigo: number): Promise<EixoPrograma> {
    return this.http.get(`${this.eixoProgramaUrl}/completo/${codigo}`)
      .toPromise()
      .then(response => {
        const eixoPrograma = response.json() as EixoPrograma;
        return eixoPrograma;
      });
  }


  contadorPerResEixo(idEixo: number, idProponente): Promise<any> {
    return this.http.get(`${this.eixoProgramaUrl}/qtd-questoes-respostas/${idEixo}/${idProponente}`)
      .toPromise()
      .then(response => {
        const count = response.json();
        return count;
      });
  }

  contadorPerResEixoBeneficiario(idEixo: number, idBeneficiario): Promise<any> {
    return this.http.get(`${this.eixoProgramaUrl}/qtd-questoes-respostas/beneficiario/${idEixo}/${idBeneficiario}`)
      .toPromise()
      .then(response => {
        const count = response.json();
        return count;
      });
  }


  buscarPorEixoEntidade(eixo: number, entidade: number): Promise<any> {
    return this.http.get(`${this.eixoProgramaUrl}/questoes-respostas-eixo-proponente/${eixo}/${entidade}`)
      .toPromise()
      .then(response => {
        const eixoPrograma = response.json();
        return eixoPrograma;
      });
  }
}
