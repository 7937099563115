export const environment = {
  production: false,

  // ============> SIGRP LOCAL
  //   apiUrl:             'http://localhost:8080', 
  // downloadFileUrl:    'http://localhost:8000/download',
  // uploadFileUrl:      'http://localhost:8000/upload',
  // report:             'http://localhost:8000/report',
  // merge:              'http://localhost:8000/merge',
  // geraPlanoTrabalho:  'http://localhost:8000/render-html-plano',
  // cepim:              'http://localhost:8000/cepim',
  // licitacao:          'http://localhost:8000/licitacao',
  // token:              'http://localhost:8000/send-mail/token',
  // agendamento:        'http://localhost:8000/send-mail/agendamento',
  // envioEmailSimples:  'http://localhost:8000/send-mail/simples',
  // validacao:          'http://localhost:4200/outras-opcoes/validacao',
  // validarPresencial:  'http://localhost:4200/validar/presencial',

  // ============> SIGRP TREINAMENTO
  // apiUrl:             'https://treinamento.sigrp.com.br:8443/',
  // downloadFileUrl:    'https://ead.medsafebrasil.com.br:8001/download',
  // uploadFileUrl:      'https://ead.medsafebrasil.com.br:8001/upload',
  // report:             'https://ead.medsafebrasil.com.br:8001/report',
  // merge:              'https://ead.medsafebrasil.com.br:8001/merge',
  // geraPlanoTrabalho:  'https://ead.medsafebrasil.com.br:8001/render-html-plano',
  // cepim:              'https://ead.medsafebrasil.com.br:8001/cepim',
  // licitacao:          'https://ead.medsafebrasil.com.br:8001/licitacao',
  // token:              'https://ead.medsafebrasil.com.br:8001/send-mail/token',
  // agendamento:        'https://ead.medsafebrasil.com.br:8001/send-mail/agendamento',
  // envioEmailSimples:  'https://ead.medsafebrasil.com.br:8001/send-mail/simples',
  // validacao:          'https://treinamento.sigrp.com.br:4200/outras-opcoes/validacao',
  // validarPresencial:  'http:///treinamento.sigrp.com.br:4200/validar/presencial',

  // ============> SIGRP PROD
  apiUrl:            'https://sigrp.pi.gov.br:8444', 
  downloadFileUrl:   'https://sigrp.pi.gov.br:8000/download',
  uploadFileUrl:     'https://sigrp.pi.gov.br:8000/upload',
  report:            'https://sigrp.pi.gov.br:8000/report',
  merge:             'https://sigrp.pi.gov.br:8000/merge',
  geraPlanoTrabalho: 'https://sigrp.pi.gov.br:8000/render-html-plano',
  cepim:             'https://sigrp.pi.gov.br:8000/cepim',
  licitacao:         'https://sigrp.pi.gov.br:8000/licitacao',
  token:             'https://sigrp.pi.gov.br:8000/send-mail/token',
  agendamento:       'https://sigrp.pi.gov.br:8000/send-mail/agendamento',
  envioEmailSimples: 'https://sigrp.pi.gov.br:8000/send-mail/simples',
  validacao:         'https://sigrp.pi.gov.br/outras-opcoes/validacao',
  validarPresencial: 'https://sigrp.pi.gov.br/validar/presencial',
 
  logo: "../../../assets/imgs/logo-digital-ocean.png",
  documentSignatureApplicationURL: 'https://assinador-sigrp.vercel.app/'

};