import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ToastyService } from "ng2-toasty";
import { ConfirmationService } from "primeng/components/common/api";
import { ErrorHandlerService } from "../../core/error-handler.service";
import { Usuario } from "../../core/model";
import { Page } from '../../core/pagination/pagination.model';
import { ProponenteFiltro, ProponenteService } from "../../core/services/proponente/proponente.service";
import { AuthService } from "../../seguranca/auth.service";
import { ActivatedRoute, Router } from "@angular/router";

declare let AdminLTE: any;

@Component({
  selector: "app-osc-listar",
  templateUrl: "./osc-listar.component.html"
})
export class OscListarComponent implements OnInit {
  totalRegistros = 0;
  filtro = new ProponenteFiltro();
  oscs = [];
  usuario = new Usuario();
  loading: boolean;
  param

  isCadastroOption: boolean = true;

  constructor(
    private ProponenteService: ProponenteService,
    private errorHandler: ErrorHandlerService,
    private confirmation: ConfirmationService,
    private toasty: ToastyService,
    private title: Title,
    public auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit() {
    AdminLTE.init();

    this.title.setTitle("Gerenciar entidade");
    this.usuario.perfil = this.auth.jwtPayload.perfil;

    //Pegar na rota o tipo de entidadeproponente
    this.route.params.subscribe(
      (params: any) => {
        this.param = params["tipo"]
        this.pesquisar()
      }
    );

    // if (this.router.url === "/cadastros/proponente/listar/Escola") {
    //   this.isCadastroOption = true;
    // } else {
    //   this.isCadastroOption = false;
    // }
  }


  // === CONFIGURACOES DE PAGINACAO
  page: Page;
  changePage(event) {
    this.filtro.itensPorPagina = event.size
    this.filtro.pagina = event.page
    this.ProponenteService.pesquisar(this.filtro).then(res => {
      this.page = res
      this.oscs = res.content;
    });
  }

  pesquisar(pagina = 0) {
    this.filtro.pagina = pagina;

    if(this.param == "OSC") this.filtro.tipoProponente = "0"
    if(this.param == "OS") this.filtro.tipoProponente = "1"
    if(this.param == "OSCIP") this.filtro.tipoProponente = "2"
    if(this.param == "Prefeitura") this.filtro.tipoProponente = "3"
    if(this.param == "Escola") this.filtro.tipoProponente = "4"
     if(this.param == "empresa") this.filtro.tipoProponente = "5"

    this.ProponenteService.pesquisar(this.filtro).then(resultado => {
      this.loading = true
      this.oscs = resultado.content;
      this.page = resultado;
    });
  }

  confirmarExclusao(osc: any) {
    this.confirmation.confirm({
      message: "Tem certeza que deseja excluir?",
      accept: () => {
        this.excluir(osc);
      }
    });
  }

  excluir(osc: any) {
    this.ProponenteService
      .excluir(osc.id)
      .then(() => {
        this.toasty.success("OSC excluída com sucesso!");
        this.pesquisar();
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  gotoVisualizarEntidade(param) {
    sessionStorage.setItem('viewEntidade', param)
    this.router.navigate(["/cadastros/proponente/visualizar"]);
  }

  gotoEditEntidade(param) {
    this.router.navigate(["/cadastros/proponente/osc-editar"]);
    sessionStorage.setItem('editEntidade', param)
  }

}
