import { URLSearchParams } from '@angular/http';
import { Injectable } from '@angular/core';

import { AuthHttp } from 'angular2-jwt';
import 'rxjs/add/operator/toPromise';

import { Extrato } from '../../model';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../seguranca/auth.service';

export class ExtratoFiltro {
  itemPlanoContas
  entidadeProponente
  infoextrato
  tipoExtrato
  tipoTransferencia
  contaBancaria
  despesaId
  pagamentoPessoalId
  planoTrabalhoHasProponenteId
  financeiroId
  recursoFinanceiro;

  dataCadastroInicial
  dataCadastroFinal

  de;
  ate;

  pagina = 0;
  itensPorPagina = 20;
}

@Injectable()
export class ExtratoService {

    url: string;
  urlDto: string;

  constructor(private http: AuthHttp,
    public auth: AuthService, ) {
    this.url = `${environment.apiUrl}/extrato`;
    this.urlDto = `${environment.apiUrl}/extrato-dto`;
  }

  pesquisar(filtro: ExtratoFiltro): Promise<any> {
    const params = new URLSearchParams();

    params.set('page', filtro.pagina.toString());
    params.set('size', filtro.itensPorPagina.toString());

    if (filtro.tipoExtrato) {
      params.set('tipoExtrato', filtro.tipoExtrato);
    }
    if (filtro.itemPlanoContas) {
      params.set('itemPlanoContas', filtro.itemPlanoContas);
    }
    if (filtro.infoextrato) {
      params.set('infoextrato', filtro.infoextrato);
    }
    if (filtro.entidadeProponente) {
      params.set('entidadeProponente', filtro.entidadeProponente);
    }
    if (filtro.tipoTransferencia) {
      params.set('tipoTransferencia', filtro.tipoTransferencia);
    }
    if (filtro.contaBancaria) {
      params.set('contaBancaria', filtro.contaBancaria);
    }
    if (filtro.despesaId) {
      params.set('despesaId', filtro.despesaId);
    }
    if (filtro.pagamentoPessoalId) {
      params.set('pagamentoPessoalId', filtro.pagamentoPessoalId);
    }
    if (filtro.planoTrabalhoHasProponenteId) {
      params.set('planoTrabalhoHasProponenteId', filtro.planoTrabalhoHasProponenteId);
    }
    if (filtro.financeiroId) {
      params.set('financeiroId', filtro.financeiroId);
    }
    if (filtro.recursoFinanceiro) {
      params.set('recursoFinanceiro', filtro.recursoFinanceiro);
    }

    return this.http.get(`${this.url}`, { search: params })
      .toPromise()
      .then(response => {
        return response.json();
      })
  }

  pesquisarDto(filtro: ExtratoFiltro): Promise<any> {
    const params = new URLSearchParams();

    params.set('page', filtro.pagina.toString());
    params.set('size', filtro.itensPorPagina.toString());

    if (filtro.tipoExtrato) {
      params.set('tipoExtrato', filtro.tipoExtrato);
    }
    if (filtro.itemPlanoContas) {
      params.set('itemPlanoContas', filtro.itemPlanoContas);
    }
    if (filtro.infoextrato) {
      params.set('infoextrato', filtro.infoextrato);
    }
    if (filtro.entidadeProponente) {
      params.set('entidadeProponente', filtro.entidadeProponente);
    }
    if (filtro.tipoTransferencia) {
      params.set('tipoTransferencia', filtro.tipoTransferencia);
    }
    if (filtro.contaBancaria) {
      params.set('contaBancaria', filtro.contaBancaria);
    }
    if (filtro.despesaId) {
      params.set('despesaId', filtro.despesaId);
    }
    if (filtro.pagamentoPessoalId) {
      params.set('pagamentoPessoalId', filtro.pagamentoPessoalId);
    }
    if (filtro.planoTrabalhoHasProponenteId) {
      params.set('planoTrabalhoHasProponenteId', filtro.planoTrabalhoHasProponenteId);
    }
    if (filtro.financeiroId) {
      params.set('financeiroId', filtro.financeiroId);
    }

    return this.http.get(`${this.urlDto}`, { search: params })
      .toPromise()
      .then(response => {
        return response.json();
      })
  }

  extratoReport(filtro: ExtratoFiltro): Promise<any> {
    const params = new URLSearchParams();

    params.set('page', filtro.pagina.toString());
    params.set('size', filtro.itensPorPagina.toString());

    if (filtro.tipoExtrato) {
      params.set('tipoExtrato', filtro.tipoExtrato);
    }
    if (filtro.itemPlanoContas) {
      params.set('itemPlanoContas', filtro.itemPlanoContas);
    }
    if (filtro.infoextrato) {
      params.set('infoextrato', filtro.infoextrato);
    }
    if (filtro.entidadeProponente) {
      params.set('entidadeProponente', filtro.entidadeProponente);
    }
    if (filtro.tipoTransferencia) {
      params.set('tipoTransferencia', filtro.tipoTransferencia);
    }
    if (filtro.contaBancaria) {
      params.set('contaBancaria', filtro.contaBancaria);
    }
    if (filtro.despesaId) {
      params.set('despesaId', filtro.despesaId);
    }
    if (filtro.pagamentoPessoalId) {
      params.set('pagamentoPessoalId', filtro.pagamentoPessoalId);
    }
    if (filtro.planoTrabalhoHasProponenteId) {
      params.set('planoTrabalhoHasProponenteId', filtro.planoTrabalhoHasProponenteId);
    }
    if (filtro.financeiroId) {
      params.set('financeiroId', filtro.financeiroId);
    }

    return this.http.get(`${this.urlDto}/report`, { search: params })
      .toPromise()
      .then(response => {
        return response.json();
      })
  }

  pesquisarDtoRelatorioDespesaReceita(filtro: ExtratoFiltro): Promise<any> {
    const params = new URLSearchParams();

    params.set('page', filtro.pagina.toString());
    params.set('size', filtro.itensPorPagina.toString());

    if (filtro.tipoExtrato) {
      params.set('tipoExtrato', filtro.tipoExtrato);
    }
    if (filtro.itemPlanoContas) {
      params.set('itemPlanoContas', filtro.itemPlanoContas);
    }
    if (filtro.infoextrato) {
      params.set('infoextrato', filtro.infoextrato);
    }
    if (filtro.entidadeProponente) {
      params.set('entidadeProponente', filtro.entidadeProponente);
    }
    if (filtro.tipoTransferencia) {
      params.set('tipoTransferencia', filtro.tipoTransferencia);
    }
    if (filtro.contaBancaria) {
      params.set('contaBancaria', filtro.contaBancaria);
    }
    if (filtro.despesaId) {
      params.set('despesaId', filtro.despesaId);
    }
    if (filtro.pagamentoPessoalId) {
      params.set('pagamentoPessoalId', filtro.pagamentoPessoalId);
    }
    if (filtro.planoTrabalhoHasProponenteId) {
      params.set('planoTrabalhoHasProponenteId', filtro.planoTrabalhoHasProponenteId);
    }
    if (filtro.financeiroId) {
      params.set('financeiroId', filtro.financeiroId);
    }

    if (filtro.dataCadastroInicial) {
      params.set('dataCadastroInicial', filtro.dataCadastroInicial);
    }
    if (filtro.dataCadastroFinal) {
      params.set('dataCadastroFinal', filtro.dataCadastroFinal);
    }

    return this.http.get(`${this.urlDto}/relatorio-despesa-receita`, { search: params })
      .toPromise()
      .then(response => {
        return response.json();
      })
  }
  
  pesquisarDtoRelatorioDespesaReceitaInfoCompleta(filtro: ExtratoFiltro): Promise<any> {
    const params = new URLSearchParams();

    params.set('page', filtro.pagina.toString());
    params.set('size', filtro.itensPorPagina.toString());

    if (filtro.tipoExtrato) {
      params.set('tipoExtrato', filtro.tipoExtrato);
    }
    if (filtro.itemPlanoContas) {
      params.set('itemPlanoContas', filtro.itemPlanoContas);
    }
    if (filtro.infoextrato) {
      params.set('infoextrato', filtro.infoextrato);
    }
    if (filtro.entidadeProponente) {
      params.set('entidadeProponente', filtro.entidadeProponente);
    }
    if (filtro.tipoTransferencia) {
      params.set('tipoTransferencia', filtro.tipoTransferencia);
    }
    if (filtro.contaBancaria) {
      params.set('contaBancaria', filtro.contaBancaria);
    }
    if (filtro.despesaId) {
      params.set('despesaId', filtro.despesaId);
    }
    if (filtro.pagamentoPessoalId) {
      params.set('pagamentoPessoalId', filtro.pagamentoPessoalId);
    }
    if (filtro.planoTrabalhoHasProponenteId) {
      params.set('planoTrabalhoHasProponenteId', filtro.planoTrabalhoHasProponenteId);
    }
    if (filtro.financeiroId) {
      params.set('financeiroId', filtro.financeiroId);
    }

    if (filtro.dataCadastroInicial) {
      params.set('dataCadastroInicial', filtro.dataCadastroInicial);
    }
    if (filtro.dataCadastroFinal) {
      params.set('dataCadastroFinal', filtro.dataCadastroFinal);
    }

    return this.http.get(`${this.urlDto}/relatorio-despesa-receita-info`, { search: params })
      .toPromise()
      .then(response => {
        return response.json();
      })
  }

  pesquisarDtoRelatorioCotacao(filtro: ExtratoFiltro): Promise<any> {
    const params = new URLSearchParams();

    params.set('page', filtro.pagina.toString());
    params.set('size', filtro.itensPorPagina.toString());

    if (filtro.tipoExtrato) {
      params.set('tipoExtrato', filtro.tipoExtrato);
    }
    if (filtro.itemPlanoContas) {
      params.set('itemPlanoContas', filtro.itemPlanoContas);
    }
    if (filtro.infoextrato) {
      params.set('infoextrato', filtro.infoextrato);
    }
    if (filtro.entidadeProponente) {
      params.set('entidadeProponente', filtro.entidadeProponente);
    }
    if (filtro.tipoTransferencia) {
      params.set('tipoTransferencia', filtro.tipoTransferencia);
    }
    if (filtro.contaBancaria) {
      params.set('contaBancaria', filtro.contaBancaria);
    }
    if (filtro.despesaId) {
      params.set('despesaId', filtro.despesaId);
    }
    if (filtro.pagamentoPessoalId) {
      params.set('pagamentoPessoalId', filtro.pagamentoPessoalId);
    }
    if (filtro.planoTrabalhoHasProponenteId) {
      params.set('planoTrabalhoHasProponenteId', filtro.planoTrabalhoHasProponenteId);
    }
    if (filtro.financeiroId) {
      params.set('financeiroId', filtro.financeiroId);
    }

    if (filtro.dataCadastroInicial) {
      params.set('dataCadastroInicial', filtro.dataCadastroInicial);
    }
    if (filtro.dataCadastroFinal) {
      params.set('dataCadastroFinal', filtro.dataCadastroFinal);
    }

    return this.http.get(`${this.urlDto}/relatorio-cotacao`, { search: params })
      .toPromise()
      .then(response => {
        return response.json();
      })
  }


  listarTodos(): Promise<any> {
    return this.http.get(this.url)
      .toPromise()
      .then(response => response.json().content);
  }

  excluir(codigo: number): Promise<void> {
    return this.http.delete(`${this.url}/${codigo}`)
      .toPromise()
      .then(() => null);
  }

  excluirTransferencia(codigo: number): Promise<void> {
    return this.http.delete(`${this.url}/transferencia/${codigo}`)
      .toPromise()
      .then(() => null);
  }

  adicionar(entidade: Extrato): Promise<Extrato> {
    return this.http.post(this.url, JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json());
  }
  
  transferencia(entidade: Extrato): Promise<Extrato> {
    return this.http.post(`${this.url}/transferencia`, JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json());
  }

  atualizar(entidade: Extrato): Promise<Extrato> {
    return this.http.put(`${this.url}/${entidade.id}`,
      JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json() as Extrato);
  }

  atualizarTransferencia(entidade: Extrato): Promise<Extrato> {
    return this.http.put(`${this.url}/transferencia/${entidade.id}`,
      JSON.stringify(entidade))
      .toPromise()
      .then(response => response.json() as Extrato);
  }

  buscarPorCodigo(codigo: number): Promise<Extrato> {
    return this.http.get(`${this.url}/${codigo}`)
      .toPromise()
      .then(response => {
        const entidade = response.json() as Extrato;
        return entidade;
      });
  }

}
