export class Endereco {
  endereco: string;
  numero: string;
  complemento: string;
  bairro: string;
  cep: string;
  cidade: string;
  estado: string;

  excluido;
}

//PATRIMONIO
export class CategoriaPatrimonio {
  id;
  descricao;
  observacao;
  data;
  usuario = new Usuario();
  entidadeProponente = new Osc();
  entidadeConcedente = new Entidade();
}
export class Patrimonio {
  id;
  codigo;
  descricao;
  observacao;
  unidade;
  dataAquisicao;
  frequenciaDepreciacao; //meses
  porcentagemDepreciacao;
  diaDepreciacao;// calcular o dia  
  status;
  valor;
  anexo;
  hashAnexo;
  tamanhoAnexo;
  data; //automaticamente
  usuario = new Usuario(); //automaticamente
  localArmazenamento = new LocalArmazenamento(); //não obrigatório
  fornecedor = new Fornecedor(); //não obrigatório
  categoriaPatrimonio = new CategoriaPatrimonio() //obrigatório 
}
export class LogPatrimonio {
  id;
  observacao;
  dataLog;
  usuario = new Usuario();
  valorAlterado;
  valorAntigo;
  patrimonio = new Patrimonio();
}

export class Fornecedor {
  id;
  nome;
  telefone;
  email;
  cnpj;
  cep;
  endereco;
  numero;
  complemento;
  bairro;
  cidade;
  estado;
  observacao;
  data;
  usuario = new Usuario();
  entidadeProponente = new Osc();
  entidadeConcedente = new Entidade()
}
export class LocalArmazenamento {
  id;
  nome;
  telefone;
  email;
  cep;
  endereco;
  numero;
  complemento;
  bairro;
  cidade;
  estado;
  observacao;
  data;
  usuario = new Usuario();
  entidadeProponente = new Osc();
  entidadeConcedente = new Entidade()
}
//PATRIMONIO FIM

export class Usuario {
  id: number;
  nome: string;
  email: string;
  telefone;
  senha;
  senha_antiga;
  cpf: string;
  rg: string;
  perfil = new Perfil();
  endereco = new Endereco();
  matricula;
  situacao
  efetivo;
  qualificacao;
  token_email;
  funcao;
  sexo;
  dataNascimento;
  registroProfissional
  tipoRegistro
  ufRegistro
  tipoDocumento
  excluido;
  entidadeConcedente
  entidadeProponente
}

export class EsqueciSenha {
  email: String;
  token: String;
  senha: String;

  // excluido;
}

export class Cidadao {
  id: number;
  nome: string;
  email: string;
  cpf: string;
  rg: String;
  senha: String;
  endereco = new Endereco();

  excluido;
}

export class EntidadeUsuarioAreaPublicaDTO {
  entidadeDto = new Osc()
  usuarioDto = new Cidadao()
}

export class ConcedenteUsuarioNovoDTO {
  entidadeDto = new Entidade()
  usuarioDto = new Cidadao()
}

export class Perfil {
  id: number;
  nome: string;
  descricao: string;
  permissao = new Permissao();
  excluido;
}
export class Permissao {
  id: number;
  descricao: string;
  atribuicoes: string;
  modulo: string;
  excluido;
}
export class PerfilPermissao {
  id: number;
  status: boolean;
  perfil = new Perfil();
  permissao = new Permissao();
  excluido;
}
export class PerfilHasPerfil {
  id: number;
  status: boolean;
  perfilPrincipal = new Perfil();
  perfilPermitido = new Perfil();
  excluido;
}

// Apagar esse aqui
export class Edital {
  id: number;
  tipo: number;
  titulo: string;
  areaAtuacao: string;
  periodoIniAvaliacaoProj: Date;
  periodoFimAvaliacaoProj: Date;
  flagemendaParlamentar: boolean;
  numeroEmendaParlamentar: string;
  inexigivelChamamento: boolean;
  justInexigivelChamamento: string;
  dispensavelChamamento: boolean;
  jusDispensavelChamamento: string;
  status: number;
  periodoIniAvaliacaoTecnicaEJuridica: Date;
  periodoFimAvaliacaoTecnicaEJuridica: Date;
  periodoIniVigenciaParceria: Date;
  periodoFimVigenciaParceria: Date;

  excluido;
}

export class EsqueciEmail {
  email: String;

  excluido;
}

export class EixoProgramaChamamento {
  id: number;
  nome: string;
  descricao: string;
  fonte: string;
  projetoAtividade: string;
  elementoDespesa: string;

  planoTrabalho;
  chamamentoPublico = new EditalChamamento();
  excluido;
}

export class RequisitosDocumentos {
  id: number;
  nome: string;
  eixoProgramaChamamento = new EixoProgramaChamamento();

  documento: any;

  excluido;
}

export class Documento {
  id: number;
  descricao: string;
  requisitosDocumentos = new RequisitosDocumentos();

  excluido;
}

export class DocumentoChamamentoPublico {
  id: number;
  anexo: string;
  hashFile: string;
  comissaoSelecaoHasUsuarioConcedente: number;

  situacao: number;
  entidadeproponente = new Osc();
  documento = new Documento();

  excluido;
}

export class MovimentacaoDocumento {
  id: number;
  logMovimentacao: string;
  movimentacaoConcedente: string;
  dataMovimentacao: any;
  situacao: string;
  usuarioProponente = new Usuario();
  comissaoSelecaoHasUsuarioConcedente: number;
  documentoChamamentoPublico = new DocumentoChamamentoPublico();

  excluido;
}

// ======= CREDENCIAMENTO ======

export class EditalCredenciamento {
  id: number;
  titulo: string;
  resumo: string;
  periodoIniDefinRequisitosCred: Date;
  periodoFimDefinRequisitosCred: Date;
  periodoIniManifestacaoInteresse: Date;
  periodoFimManifestacaoInteresse: Date;
  periodoIniAutenticarDoc: Date;
  periodoFimAutenticarDoc: Date;
  //usuarioAdmin = new Usuario();
  statusRegistro: number;
  statusEdital: number;
  dataCadastro: Date;
  ultimaAtualizacao: Date;
  entidadeConcedente = new Entidade();

  dataPublicacaoEdital: Date;
  periodoPublicPreliminar: Date;
  periodoIniRecurso: Date;
  periodoFimRecurso: Date;
  periodoIniAnalRecurso: Date;
  periodoFimAnalRecurso: Date;
  dataPublicacaoFinal: Date;
  tipoParceria: number;
  editalCredenciamentoPontuacao: number;

  comissaoSelecao = new ComissaoSelecao();

  eixoPrograma: any;

  cancelado;
  justificativa;
  anexo;
  hashAnexo;

  excluido;
}

export class EixoPrograma {
  id: number;
  nome: string;
  descricao: string;
  editalCredenciamento = new EditalCredenciamento();

  requisitoCredenciamento: any;

  excluido;
}

export class RequisitoCredenciamento {
  id: number;
  titulo: string;
  eixoPrograma = new EixoPrograma();

  questaoRequisito: any;

  excluido;
}

export class QuestaoRequisito {
  id: number;
  descricao: string;
  recomendacao: string;
  requisitoCredenciamento = new RequisitoCredenciamento();

  excluido;
}

export class QuestaoRequisitoProponente {
  id: number;
  status: number;
  anexo: string;
  hashFile: string;
  questaoRequisito = new QuestaoRequisito();
  entidadeProponente = new Osc();
  justificativa;
  dataValidade;
  excluido;
}

export class MovimentacaoRequisito {
  id: number;
  dataMovimentacao: any;
  logMovimentacao: string;

  situacao: string;
  usuarioProponente = new Usuario();

  movimentacaoConcedente: string;
  comissao_selecao_has_usuario_concedente: number;
  questaoRequisitoProponente = new QuestaoRequisitoProponente();

  excluido;
}

export class EmendaParlamentar {
  id: number;
  titulo: string;
  dataPublicacao: Date;
  resumo: string;
  tipo: any;
  entidadeProponente = new Osc();
  entidadeConcedente = new Entidade();

  periodoIniManifestacaoInteresse: Date;
  periodoFimManifestacaoInteresse: Date;
  periodoIniAutenticarDoc: Date;
  periodoFimAutenticarDoc: Date;
  periodoPublicPreliminar: Date;
  periodoIniRecurso: Date;
  periodoFimRecurso: Date;
  periodoIniAnalRecurso: Date;
  periodoFimAnalRecurso: Date;
  dataPublicacaoFinal: Date;

  excluido;
}

export class Demanda {
  id: number;
  demanda: string;
  objeto: string;
  tipoDemanda: string;
  tipoFomento: any;
  situacao: string;
  demandaAvalNotas: boolean;
  dotacaoOrcamentaria: string;
  areaAtuacao = new AreaDeAtuacao();

  criterioSelecao: any;
  justificativaCriterioSelecao: string;
  qtdEntidadesEscolhidasSorteio: string;

  periodoPublicacaoDemanda: Date;
  periodoIniSub: Date;
  periodoFimSub: Date;
  periodoIniAva: Date;
  periodoFimAva: Date;
  dataPublicacaoParcial: Date;
  periodoIniRecurso: Date;
  periodoFimRecurso: Date;
  periodoIniAvalRecurso: Date;
  periodoFimAvalRecurso: Date;
  periodoIniElaboracaoTermo: Date;
  periodoFimElaboracaoTermo: Date;
  periodoIniAvaliacaoProcurador: Date;
  periodoFimAvaliacaoProcurador: Date;
  dataPublicacaoFinal: Date;
  periodoIniMudancaExecucao: Date;
  periodoFimMudancaExecucao: Date;

  eixoPrograma = new EixoPrograma();
  eixoProgramaChamamento = new EixoProgramaChamamento();
  emendaParlamentar = new EmendaParlamentar();

  comissaoSelecao = new ComissaoSelecao();
  entidadeConcedente = new Entidade();

  eixoProgramaHasProponenteCredenciado = new EixoProgramaHasProponenteCredenciado();
  entidadeProponente = new Osc();

  demandaHasEntidadeProponenteEscolhida: DemandaHasEntidadeProponenteEscolhida[];

  excluido;

  //Atributo não usado
  editalCredenciamento = new EditalCredenciamento();
}

export class DemandaHasEntidadeProponenteInteressada {
  id: number;
  demanda = new Demanda();
  entidadeProponente = new Osc();
  situacao: any;
  statusFinal: any;
  pontuacaoFinal: any;

  profissionaisOscSituacao: any;
  profissionaisOscStatus: any;
  profissionaisOscPontuacao: number;
  profissionaisOscAvaliacao: string;

  instalacaoFisicaSituacao: any;
  instalacaoFisicaStatus: any;
  instalacaoFisicaPontuacao: number;
  instalacaoFisicaAvaliacao: string;

  equipamentosDisponiveisSituacao: any;
  equipamentosDisponiveisStatus: any;
  equipamentosDisponiveisPontuacao: number;
  equipamentosDisponiveisAvaliacao: string;

  cronogramaFisicoSituacao: any;
  cronogramaFisicoStatus: any;
  cronogramaFisicoPontuacao: number;
  cronogramaFisicoAvaliacao: string;

  naturezaDespesaSituacao: any;
  naturezaDespesaStatus: any;
  naturezaDespesaPontuacao: number;
  naturezaDespesaAvaliacao: string;

  detalhamentoDespesaSituacao: any;
  detalhamentoDespesaStatus: any;
  detalhamentoDespesaPontuacao: number;
  detalhamentoDespesaAvaliacao: string;

  cronogramaDesembolsoSituacao: any;
  cronogramaDesembolsoStatus: any;
  cronogramaDesembolsoPontuacao: number;
  cronogramaDesembolsoAvaliacao: string;
}

export class Entidade {
  id: any;
  nome: string;
  cnpj: string;

  ddd: string;
  telefone: string;
  fax: string;
  email: string;
  site: string;

  banco: string;
  agencia: string;
  contaCorrente: string;
  pracaPagamento: string;

  dataCadastro: Date;
  chefe = new Usuario();
  endereco = new Endereco();

  tipoConcedente: any;
  logotipo: any;

  excluido;
}

export class Osc {
  id: any;
  nome: string;
  razaoSocial: string;
  cnpj: string;

  ddd: string;
  telefone: string;
  fax: string;
  email: string;
  site: string;

  banco: string;
  agencia: string;
  contaCorrente: string;
  pracaPagamento: string;

  dataCadastro: Date;
  chefe = new Usuario();
  endereco = new Endereco();

  // habilitacao = new Habilitacao();
  statusHabilitacao: any;
  hashHabilitacao: any;
  tipoProponente: any;
  idConcedente
  excluido;
  ativoMatricula;
  existePendenciaHabilitacao:boolean;
}

export class ModeloTermo {
  id: number;
  titulo: string;
  descricao: string;
  termo: string;
  tipo: any;
  categoria: any;
  entidadeConcedente = new Entidade();
}

export class ComissaoSelecao {
  id: number;
  nome: string;
  email: string;
  entidadeConcedente = new Entidade();

  excluido;
}

export class AreaDeAtuacao {
  id: number;
  nome: string;
  descricao: string;

  excluido;
}

export class EntidadeConcedenteHasAreaDeAtuacao {
  id: number;
  entidadeConcedente = new Entidade();
  areaAtuacao = new AreaDeAtuacao();

  excluido;
}

export class EntidadeProponenteHasAreaDeAtuacao {
  id: number;
  entidadeProponente = new Osc();
  areaAtuacao = new AreaDeAtuacao();

  excluido;
}

export class EntidadeProponenteHasUsuario {
  id: number;
  entidadeProponente = new Osc();
  usuario = new Usuario();

  excluido;
}

export class EntidadeConcedenteHasUsuario {
  id: number;
  entidadeConcedente = new Entidade();
  usuario = new Usuario();

  excluido;
}

//========== EXECUÇÃO ==========//
export class Parceria {
  id: number;
  local: string;
  dataInicio: Date;
  dataFim: Date;
  status: string;
  valorTotalDestinado: number;
  repasseLiberado: string;
  valorGastoPelaEntidade: number;
  valorRestante: number;
  entidadeConcedente = new Entidade();
  entidadeProponente = new Osc();
  usuarioGestorParceria = new Usuario();

  excluido;
}

export class CronogramaFinanceiro {
  id: number;
  descricaoValor: string;
  mes: string;
  valor: number;
  anexo: string;
  status: string;
  parceria = new Parceria();
  entidadeConcedente = new Entidade();
  entidadeProponente = new Osc();

  excluido;
}

//========== //EXECUÇÃO ==========//

export class RequisitoProposta {
  id: number;
  questao: string;
  descricaoQuestao: string;
  demanda = new Demanda();

  excluido;
}

//========== RESPOSTAS DEMANDA ==========//

export class RespostaRequisitoProposta {
  id: number;
  resposta: string;
  pontuacao: number;
  avaliacao: string;
  situacao: any;
  status: any;
  entidadeProponente = new Osc();
  requisitosProposta = new RequisitoProposta();

  excluido;
}

export class RespostaProfissionaisOSC {
  id: number;
  nome: string;
  formacao: string;
  funcaoEntidade: string;
  cargaHoraria: string;
  pontuacao: number;
  avaliacao: string;
  situacao: any;
  status: any;
  entidadeProponente = new Osc();
  demanda = new Demanda();

  excluido;
}

export class RespostaInstalacaoFisica {
  id: number;
  comodo: string;
  quantidade: number;
  tipoAtividadeDesenvolvida: string;
  pontuacao: number;
  avaliacao: string;
  situacao: any;
  status: any;
  entidadeProponente = new Osc();
  demanda = new Demanda();

  excluido;
}

export class RespostaEquipamentosDisponiveis {
  id: number;
  tipoEquipamento: string;
  quantidade: number;
  pontuacao: number;
  avaliacao: string;
  situacao: any;
  status: any;
  entidadeProponente = new Osc();
  demanda = new Demanda();

  excluido;
}

export class RespostaCronogramaFisico {
  id: number;
  meta: string;
  etapa: string;
  especificacao: string;
  unidade: string;
  quantidade: number;
  inicio: Date;
  termino: Date;
  pontuacao: number;
  avaliacao: string;
  situacao: any;
  status: any;
  entidadeProponente = new Osc();
  demanda = new Demanda();

  excluido;
}

export class RespostaNaturezaDespesa {
  id: number;
  codigo: string;
  especificacao: string;
  total: number;
  pontuacao: number;
  avaliacao: string;
  situacao: any;
  status: any;
  entidadeProponente = new Osc();
  demanda = new Demanda();

  excluido;
}

//Esta classe foi criada pq RespostaDetalhamentoDespesa não aceitou que
//RespostaNaturezaDaDespesa tivesse uma demanda
//TODO: analisar isso posteriormente
export class RespostaNaturezaDaDespesa {
  id: number;
  codigo: string;
  especificacao: string;
  total: number;
  pontuacao: number;
  avaliacao: string;
  situacao: any;
  status: any;
  entidadeProponente = new Osc();
}
export class RespostaDetalhamentoDespesa {
  id: number;
  natureza: string;
  especificacao: string;
  un: string;
  quantidade: number;
  valorUnitario: number;
  valorTotal: number;
  pontuacao: number;
  avaliacao: string;
  situacao: any;
  status: any;
  entidadeProponente = new Osc();
  naturezaDaDespesa = new RespostaNaturezaDaDespesa();
}

export class RespostaCronogramaDesembolso {
  id: number;
  mes: Date;
  valor: number;
  declaracaoProponente: boolean;
  pontuacao: number;
  avaliacao: string;
  situacao: any;
  status: any;
  entidadeProponente = new Osc();
  demanda = new Demanda();

  excluido;
}

export class LogRespostaQuestoesPlanoTrabalho {
  id: number;
  logMovimentacao: string;
  pontuacao: number;
  situacao: any;
  status: any;
  dataMovimentacao: Date;

  respotaRequisitoProposta = new RespostaRequisitoProposta();
  respostaProfissionaisOSC = new RespostaProfissionaisOSC();
  respostaInstalacaoFisica = new RespostaInstalacaoFisica();
  respostaEquipamentosDisponiveis = new RespostaEquipamentosDisponiveis();
  respostaCronogramaFisico = new RespostaCronogramaFisico();
  respostaNaturezaDespesa = new RespostaNaturezaDespesa();
  respostaDetalhamentoDespesa = new RespostaDetalhamentoDespesa();
  repostaCronogramaDesembolso = new RespostaCronogramaDesembolso();

  usuarioIdProponente = new Usuario();

  // === verificar esse atributo
  comissaoSelecaoHasUsuarioConcedente: number;

  excluido;
}

export class EixoProgramaHasProponenteCredenciado {
  id: number;
  entidadeProponente = new Osc();
  eixoPrograma = new EixoPrograma();
  status: any;
  situacao: any;
  excluido;
}

export class EixoHasProponenteCadastrada {
  id: number;
  entidadeProponente = new Osc();
  eixoPrograma = new EixoPrograma();
  status: any;
  situacao: any;
  tipo: any;
  excluido;
}
export class Habilitacao {
  id: number;
  nome: string;
  descricao: string;
  tipo: string;
  quantidadeAgendamentos:number;
  requisitoHabilitacao: any;
  excluido;
}
export class RequisitoHabilitacao {
  id: number;
  titulo: string;
  habilitacao = new Habilitacao();
  questaoRequisitoHabilitacao: any;
  excluido;
}
export class QuestaoRequisitoHabilitacao {
  id: number;
  descricao: string;
  recomendacao: string;
  requisitoHabilitacao = new RequisitoHabilitacao();
  excluido;
}
export class RespostaQuestaoRequisitoHabilitacao {
  id: number;
  status: string; // [ Reprovado, Aprovado, Aprovado_com_ressalvas ]
  situacao: string; // [ Em_avaliacao, Avaliado, Nao_avaliado ]
  anexo: string;
  justificativa: string;
  hashFile: string;
  dataValidade: Date;
  entidadeProponente = new Osc();
  questaoRequisitoHabilitacao = new QuestaoRequisitoHabilitacao();
  excluido;
}
export class HabilitacaoHasProponente {
  id: number;
  statusProcesso: string; // [ Em_envio_documentos, Aguardando_confirmacao_agendamento, Agendamento_confirmado, Agendamento_remarcado ]
  horaAgendamento;
  dataSolicitacao;
  usuarioSolicitacao;
  dataAvaliacao;
  usuarioAvaliacao;
  habilitacao = new Habilitacao();
  entidadeProponente = new Osc();
  existePendencia:boolean;
  descricaoPendencia:string;
  excluido;
}
export class DemandaHasEntidadeProponenteEscolhida {
  id: number;
  entidadeProponente = new Osc();
  eixoProgramaHasProponenteCredenciado = new EixoProgramaHasProponenteCredenciado();
  situacao: any;
  status: any;
  termoVigente;
}

export class DemandaHasEntidadeProponenteEscolhidaDTO {
  id: number;
  demanda = new Demanda();
  entidadeProponente = new Osc();
  eixoProgramaHasProponenteCredenciado = new EixoProgramaHasProponenteCredenciado();
  situacao: any;
  status: any;
  termoVigente;
}

export class DemandaHasEntidadeProponenteEscolhidaAtualizarDTO {
  id: number;
  demanda = new DemandaSoId();
  entidadeProponente = new Osc();
  eixoProgramaHasProponenteCredenciado = new EixoProgramaHasProponenteCredenciado();
  situacao: any;
  status: any;
  termoVigente;
}
export class DemandaSoId {
  id: number;
}

export class Termo {
  id: number;
  dataCadastro: Date;
  titulo: string;
  descricao: string;
  termo: string;
  anexo: string;
  hashFile: string;
  situacao: any;
  ppFavoravel: boolean;
  ppParecerProcurador: string;
  ppAnaliseTermoColaboracaoFomento: string;
  ppAnaliseObjeto: string;
  ppAnalisePossibilidadeParceria: string;
  ppViabilidadeExecucao: string;
  ppIrregularidade: boolean;
  ppLogIrregularidade: string;
  ppProcurador = new Usuario();
  usuarioAlterando = new Usuario();
  demandaHasEntidadeProponenteEscolhida = new DemandaHasEntidadeProponenteEscolhida();
}

export class RecursoCredenciamento {
  id: number;
  recurso: string;
  parecerComissao: string;
  status: string;
  situacao: string;
  dataEnvio;
  dataAvaliacao;
  anexo;
  hashAnexo;
  eixoProgramaHasEntidadeProponenteCadastrada;
}

export class ComissaoSelecaoHasUsuarioConcedente {
  id: number;
  comissaoSelecao = new ComissaoSelecao();
  usuario = new Usuario();
  funcao;
  qualificacao;
}

export class ComissaoAcompanhamento {
  id: number;
  nome: string;
  tipoComissao: string;
  entidadeConcedente = new Entidade();
}

export class ComissaoAcompanhamentoHasUsuarioConcedente {
  id: number;
  comissaoAcompanhamentoGestao = new ComissaoAcompanhamento();
  usuario = new Usuario();
  funcao;
  qualificacao;
}

export class EditalChamamento {
  id: number;
  titulo: string;
  descricao: string;
  dataCadastro: Date;
  periodoIniDefinRequisitosCham: Date;
  periodoFimDefinRequisitosCham: Date;
  dataPublicacaoEdital: Date;
  periodoIniEnvioProposta: Date;
  periodoFimEnvioProposta: Date;
  periodoIniAnalProposta: Date;
  periodoFimAnalProposta: Date;
  periodoPublicPreliminar: Date;
  periodoIniHabilAprov: Date;
  periodoFimHabilAprov: Date;
  periodoIniRecurso: Date;
  periodoFimRecurso: Date;
  periodoIniAnalRecurso: Date;
  periodoFimAnalRecurso: Date;
  dataPublicacaoFinal: Date;
  dataValidade: Date;
  ultimaAtualizacao: Date;
  arquivo: string;
  arquivoFileHash: string;
  termoReferencia: string;
  termoReferenciaFileHash: string;
  exibirResultado:any;
  comissaoSelecao = new ComissaoSelecao();
  entidadeConcedente = new Entidade();
  tipoAvaliacaoPlano;
  planoTrabalho;

  cancelado;
  justificativaCancelado;

  notaCorte;

  status
}

export class CancelarChamamentoModel {
  idChamamento: number;
  cancelado;
  justificativaCancelado: string;
}



// ==== Plano de trabalho === //
export class PlanoTrabalho {
  id: number;
  nome;
  descricao;
  justificativa: string

  outroRequisito;
  status;

  et2AnexoProponenteIdadeMin: string
  et2AtuacaoRede: boolean
  et2UnidadeOrcamentaria: string
  et2Fonte: string
  et2DotacaoOrcamentaria: string
  et2ElementoDespesa: string
  et2Valor;
  et2ValorExtenso: string
  et2Contrapartida: boolean
  et2ContrapartidaFinanceira: boolean
  et2ValorContrapartidaFinanceira;
  et2ContrapartidaNaoFinanceira: string

  chamamentoPublico = new EditalChamamento();
  entidadeProponente = new Osc();
  editalCredenciamento = new Edital();
  eixoPrograma = new EixoPrograma();
  celebracao = new Celebracao();
  usuario = new Usuario();
  dispensaInexigibilidade = new DispensaInexigibilidade();
  emendaParlamentar = new EmendaParlamentar();
  multiCelebracao: boolean;
}

export class CriterioQuantitativo{
  id;
	descricao;
	planoTrabalhoHasProponente = new PlanoTrabalhoHasProponente;
}

export class FichaTecnica{
  id;
	descricao;
	ficha;
	pactuado;
	realizado;
	porcentagem;
	nota;
	observacao;
	criterioQuantitativo = new CriterioQuantitativo;
}

export class PlanoTrabalhoHasProponente {
  id: number;
  situacao;
  notaFinal;
  resultadoAvPlano;
  resultadoAvPlanoObs;
  notaFinalAposRecurso;
  resultadoAvPlanoFinal;
  resultadoAvPlanoObsFinal;
  banco
  agencia
  contaCorrente
  pracaPagamento

  recebeuRecurso
  recurso
  anexoRecurso
  hashAnexoRecurso

  avRecurso
  parecerRecurso
  anexoParecer
  hashAnexoParecer
  
  entidadeProponente = new Osc();
  planoTrabalho = new PlanoTrabalho();

  descricaoRealidade;
  sinteseProposta;
  capacidadeInstalada;
  monitoramentoAvaliacao;
  cronogramaFisicoExecucaoObjeto;
  detalhamentoFinanceiro: DetalhamentoFinanceiro[]
  respostaOutroRequisitoPlano;
  alterarPlano;

  justificativaDesclassif
  anexoDesclassif
  hashAnexoDesclassif
  usuarioDesclassif = null;
  dataDesclassif
  statusDesclassif
}

export class PthpHasContaBancaria{
  id: number;
  planoTrabalhoHasProponente;
  contaBancaria;
}

export class RecursoModel{
  recurso
  anexoRecurso
  hashAnexoRecurso
}

export class ModelAvPlanoTrabalho {
  id;
  situacao;
  notaFinal;
  resultadoAvPlano;
  resultadoAvPlanoObs;
  notaFinalAposRecurso;
  resultadoAvPlanoFinal;
  resultadoAvPlanoObsFinal;
}

export class DescricaoRealidade {
  id: number;
  descricaoRealidade;
  planoTrabalhoHasProponente = new PlanoTrabalhoHasProponente();
}

export class AvaliarPlanoTrabalho {
  flag
  nota
  parecer: string
}

export class AnaliseRecurso {
  status
  parecer: string
  anexoParecer;
  hashAnexoParecer;
}

export class SinteseProposta {
  id: number;
  tituloProposta;
  identificacaoObjeto;
  objetivoGeralProposta;
  objetivoEspecificoProposta;
  justificativaProposta;
  abrangenciaProposta;
  publicoBeneficiario;
  perfilPublicoBeneficiario;
  metaAtendimento;
  dataIniExecucao: Date;
  dataFimExecucao: Date;
  metodologiaAbordagemProposta;
  planoTrabalhoHasProponente = new PlanoTrabalhoHasProponente();
}

export class CapacidadeInstalada {
  id: number;
  estruturaFisica;
  estruturaFisicaOutro;
  planoTrabalhoHasProponente = new PlanoTrabalhoHasProponente();

  versaoPlano = new VersaoPlano
  versaoAnterior
  statusAlteracao;
  justifAvalAlteracao;
  tipoAlteracao;
  ativoInativo;
  pthpId;

  instalacoesFisicas;
  equipe;
  equipamentosDisponiveis;
}

export class InstalacoesFisicas {
  id: number;
  comodo;
  quantidade;
  tipoAtividadeDesenv;
  capacidadeInstalada = new CapacidadeInstalada();

  versaoPlano
  versaoAnterior
  statusAlteracao;
  justifAvalAlteracao;
  tipoAlteracao;
  ativoInativo;
  pthpId;
  instalacaoVersao;
}
export class Equipe {
  id: number;
  nome;
  formacao;
  funcao;
  cargaHoraria;
  capacidadeInstalada = new CapacidadeInstalada();

  versaoPlano
  versaoAnterior
  statusAlteracao;
  justifAvalAlteracao;
  tipoAlteracao;
  ativoInativo;
  pthpId;
  equipeVersao;
}
export class EquipamentosDisponiveis {
  id: number;
  tipoEquipamento;
  quantidade;
  capacidadeInstalada = new CapacidadeInstalada();

  versaoPlano
  versaoAnterior
  statusAlteracao;
  justifAvalAlteracao;
  tipoAlteracao;
  ativoInativo;
  pthpId;
  equipamentoVersao;
}

export class MonitoramentoAvaliacao {
  id: number;
  tecnicaMonitoramento;
  sustentabilidadeProposta;
  planoTrabalhoHasProponente = new PlanoTrabalhoHasProponente();
}

export class CronogramaFisicoExecucaoObjeto {
  id: number;
  meta;
  descricaoMeta;
  latitude;
  longitude;
  tipoAlteracao;
  planoTrabalhoHasProponente = new PlanoTrabalhoHasProponente();
  etapaCronogramaFisico;

}

export class EtapaCronogramaFisico {
  id: number;
  especificacao;
  descricao;
  dataIni
  dataFim
  latitude;
  longitude;
  detalhamentoFinanceiroHasEtapaCronogramaFisico;
  cronogramaFisicoExecucaoObjeto = new CronogramaFisicoExecucaoObjeto();
  evidenciasHasMeta: EvidenciasHasMeta[];

  percentualEvidencia
  percentualDespesa

  tipoAlteracao;

}

export class EtapaCronogramaFisicoComDespesa {
  id: number;
  especificacao;
  descricao;
  dataIni
  dataFim
  latitude;
  longitude;
  detalhamentoFinanceiroHasEtapaCronogramaFisico;
  cronogramaFisicoExecucaoObjeto = new CronogramaFisicoExecucaoObjeto();
  evidenciasHasMeta: EvidenciasHasMeta[];

  despesa: any[];

  percentualEvidencia
  percentualDespesa
}

export class DetalhamentoFinanceiro {
  id: number;
  codigo;
  especificacao;
  total;
  tipo;
  tipoAlteracao;
  categoria = new Categoria();
  planoTrabalhoHasProponente = new PlanoTrabalhoHasProponente();
  detalhamentoFinanceiroHasEtapaCronogramaFisico;
}

export class DetalhamentoFinanceiroHasEtapaCronogramaFisico {
  id: number;
  especificacao;
  un;
  funcao;
  mes;
  mesAnoContratacao;
  mesAnoDispensa;
  quantidade;
  salario;
  inss;
  inssSemFerias;
  fgtsSemTrezeSalario
  inssSemTrezeSalario
  fgtsSemFerias;
  terceiro;
  fgts;
  pis;
  umDozeDeFerias;
  umTresDeFerias;
  umDozeDeTrezeSalario;
  multaRecisoria;
  encargoMensal;
  valorUnitario;
  totalUnitario;
  valorTotal;
  nomeEtapa;
  tipoAlteracao;
  detalhamentoFinanceiro = new DetalhamentoFinanceiro();
  etapaCronogramaFisico = new EtapaCronogramaFisico();
  equipamentoMaterialPermanente
  tipoDespesa
  beneficios
  outros
  notaExplicativa
}

export class CronFinanceiro {
  id;
  periodo;
  valor;
  planoTrabalhoHasProponente = new PlanoTrabalhoHasProponente();
}

export class CronogramaDesembolso {
  id: number;
  mes;
  valor;
  cronogramaFisicoExecucaoObjeto = new CronogramaFisicoExecucaoObjeto();
}

export class RespostaOutroRequisitoPlano {
  id: number;
  resposta;
  anexo;
  anexoFileHash;
  checkbox;
  opcaoRequisito = new OpcaoRequisito();
  outroRequisito = new OutroRequisito();
  planoTrabalhoHasProponente = new PlanoTrabalhoHasProponente();
}

export class OutroRequisito {
  id: number;
  nome: string;
  descricao: string;
  tipoQuestao;
  recomendacao: string;
  linkRecomendacao: string;
  opcaoRequisito = []
  planoTrabalho = new PlanoTrabalho();
}

export class OpcaoRequisito {
  id: number;
  opcao;
  outroRequisito = new OutroRequisito();
}

export class Categoria {
  id: number;
  categoria;
  descricao;
}
export class ParametrosContabeis {
  id;
  campo;
  descricao;
  valor;
}
export class ModelCronogramaDesembolso {
  mes;
  valor;
  etapas = [];
  metas = [];
  percentual;
}

// export class MetaExecucao{
//   metas
//   etapasMetas = new EtapaExecucao();
// }
// export class EtapaExecucao{
//   etapas = [];
// }


export class Despesa {
  id: number;
  justificativaAusenciaProposta;
  nomeFavorecido
  cpfCnpjVencedor;
  nfAnexoVencedor;
  hashFile;

  comprovanteExtratoBancario;
  hashFileComprovanteExtrato;
  comprovanteOrdemBancaria;
  hashFileOrdemBancaria;

  etapaCronogramaFisico: number;
  valor: number;
  dataPagamento;
  entidadeProponente = new Osc();
  proposta: Proposta[];

  usuario = new Usuario();
  data: any;

  pagamentoDinheiro;

  excluido;
}

export class Proposta {
  id: number;
  proposta;
  hashFile;
  propostaExterna;
  nomeFornecedorExterno;
  cnpjFornecedorExterno;

  despesa = new Despesa();

  excluido;
}

export class EvidenciasHasMeta {
  id: number;
  porcentagem: number;
  descricao;
  etapaCronogramaFisico = new EtapaCronogramaFisico();
  anexosEvidencias
  excluido;

  usuario = new Usuario();
  data: any;
  dataOcorrencia: any;
}

export class AnexosEvidencias {
  id: number;
  anexo;
  hashFile;
  evidenciasHasMeta = new EvidenciasHasMeta();

  excluido;
}

export class DetalhamentoFisicoHasDespesa {
  id: number;
  despesa = new Despesa();
  detalhamentoFinanceiroHasEtapaCronogramaFisico;

  excluido;
}

export class Celebracao {
  id: number 
  numero: string
  et1Titulo: string
  et1Objeto: string
  et1TipoDemanda: any
  et1TipoTermo: any
  sei: any
  et1EntidadeConcedente = new Entidade
  et1Convenio = new DispensaInexigibilidade
  et1Inovacao = new Inovacao
  et1Sigma = new Sigma
  et1EmendaParlamentar = new EmendaParlamentar
  et1CredenciamentoEmpresa = new CredenciamentoEmpresa
  et1ChamamentoPublico = new EditalChamamento
  et1Empresa = new Empresa
  et1DispensaInexigibilidade = new DispensaInexigibilidade
  et1AnexoTr: string
  et1AnexoTrFileHash: string
  et1TipoSubvencao: string

  et2AnexoProponenteIdadeMin: string
  et2AtuacaoRede: boolean
  et2UnidadeOrcamentaria: string
  et2Fonte: string
  et2DotacaoOrcamentaria: string
  et2ElementoDespesa: string
  et2Valor;
  et2ValorExtenso: string
  et2Contrapartida: boolean
  et2ContrapartidaFinanceira: boolean
  et2ValorContrapartidaFinanceira;
  et2ContrapartidaNaoFinanceira: string

  et3ComissaoSelecao = new ComissaoSelecao
  et3AnexoDiarioComissaoSelecao: string
  et3AnexoDiarioComissaoSelecaoHashFile: string
  et3ComissaoAcompanhamentoGestao = new ComissaoAcompanhamento
  et3AnexoComissaoAcompanhamento: string
  et3AnexoComissaoAcompanhamentoHashFile: string
  et3GestorParceriaUsuario = new Usuario

  et4PeriodoPublicacaoCelebracao: Date
  et4PeriodoIniSub: Date
  et4PeriodoFimSub: Date
  et4PeriodoIniParTec: Date
  et4PeriodoFimParTec: Date
  et4PeriodoIniAjustePlano: Date
  et4PeriodoFimAjustePlano: Date
  et4PeriodoIniElaboracaoTermo: Date
  et4PeriodoFimElaboracaoTermo: Date
  et4ParecerJuridico: boolean
  et4PeriodoIniParecerJuridico: Date
  et4PeriodoFimParecerJuridico: Date
  et4PeriodoIniAvaliacaoProcurador: Date
  et4PeriodoFimAvaliacaoProcurador: Date
  et4PeriodoIniAssinatura: Date
  et4PeriodoFimAssinatura: Date
  et4AnexoProjetoAssinado: string
  et4AnexoProjetoAssinadoHashFile: string
  et4DataPublicacaoFinal: Date

  et5PeriodoIniMudancaExecucao: Date
  et5PeriodoFimMudancaExecucao: Date
  et5PeriodoIniExecucao: Date
  et5PeriodoFimExecucao: Date
  et5PorcMaxValGlobal;
  et5PesquisaSatisfacao: boolean

  et6PeriocidadePrestContas;
  et6DataFinalPrestContas: Date

  situacao;
  status;
  pthpId;

  cancelado
  justificativaCancelamento: string;
  usuarioCancelou = new Usuario();
  dataCancelamento;

  aprovacaoGestor;
  aprovacaoGestorObs: string;
  aprovacaoGestorData;
  aprovacaoGestorUser = new Usuario();

  sig3Proponente = new Osc;
  sig3NomeConcedente;
  sig3TipoCelebracao;
  rateio;
  numeroTermo:string;
  visitaPrevia:any
}


export class CelebracaoRelatorioDTO {
  id: number;
  numero: string;
  titulo: string;
  tipoDemanda: any;
  tipoTermo: any;
  situacao:any;
  status:any;
  cancelado:any;
  concedente:any;
  proponente:any;
  tipoCelebracao:any;
  valorRecebido:any;
  valorProjeto:any;
  numeroTermo:string;

}

export class TermoCelebracao{
  id: number
  status
	tipo;
	anexo: string
	hashFile: string
	usuario = new Usuario
	dataAlteracao;
	anexoAnalise: string
	hashFileAnalise: string
	logAvaliacao: string
	aprovadoGestorConcedente;
	aprovadoGestorProponente;
	usuarioGestorConcedente = new Usuario
  usuarioGestorProponente = new Usuario
  dataAprovacaoConcedente;
  dataAprovacaoProponente;
	celebracao;
}

export class CancelaCelebracao {
  idCelebracao: number;
  cancelado: boolean;
  justificativaCancelamento: string;
  usuarioCancelou = new Usuario();
  dataCancelamento;
}

export class AprovacaoGestor {
  idCelebracao: number;
  aprovacaoGestor;
  aprovacaoGestorObs: string;
  aprovacaoGestorData;
  aprovacaoGestorUser = new Usuario();
}

export class CelebracaoAddDTO{
  celebracao;
  planoTrabalho;
  contaBancariaLista;
  rateio
}

export class Convenio {
  id: number
}

export class Inovacao {
  id: number
}

export class Sigma {
  id: number
}

export class CredenciamentoEmpresa {
  id: number
}

export class Empresa {
  id: number
}

export class DispensaInexigibilidade {
  id: number
  titulo: string
  descricao: String
  dispensa: boolean
  inexigibilidade: boolean
  dataPublicacao: Date
  anexoJustificativa: string
  anexoJustificativaHashFile: string
  usuario = new Usuario
  entidadeConcedente = new Entidade
  planoTrabalho 
  tipoProposta
  statusProposta 
  statusAvaliacao
  usuarioAvaliouProposta = new Usuario
  usuarioEnvioProposta = new Usuario
  dataCadastroProp
  dataAnaliseProp
  logAvaliacao
  origemProposta
  //areaDeAtuacao = new AreaDeAtuacao
}

export class AvaliarProposta{
  modalidade;
  acao;
  produto;
  statusProposta;
  statusAvaliacao;
  justificativa;
}

export class AnexoProposta{
  id: number
  proposta
  hashFile
  descricaoAnexo
  dispensaInexigibilidade = new DispensaInexigibilidade
}

export class ModalidadeProposta{
  id: number;
  tipo;
  nome: string;
  descricao: string;
}



export class PessoalProjeto {
  id: number
  nome: string;
  cpf: string;

  pis: string;
  rg: string;
  email: string;
  telefone: string;

  atribuicao: string
  statusPessoa: string;
  avisoPrevio: string;
  justificativaDemissao: string;
  usuario = new Usuario();

  pagamentoPessoal

  tipo
  pthpId
  detFinHasEtCronFis

  excluido
}

export class PagamentoPessoal {
  id: number
  comprovanteTransferencia: string;
  hashComprovanteTransferencia: string;
  comprovanteRecibo: string
  hashComprovanteRecibo: string;
  anexoNf: string;
  hashAnexoNf: string;
  valor: number;
  data: Date;
  dataOcorrencia: any;
  justificativaProblema: string;
  pessoalProjeto = new PessoalProjeto();
  horaTrabalhada: number;
  localidadeVisitada
  qtdDiaria

  dataIdaViagem
  dataVoltaViagem
  cidadeOrigem: string;
  cidadeDestino: string;

  tipoDespesaReceitaId
  usuarioId
  dfhecfId
  excluido
}
// === MODULO DE MODELOS ==== //
export class ModuloParecer {
  id: number
  modulo: string
}
export class SubmoduloRoteiro {
  id: number
  submodulo
  moduloParecer = new ModuloParecer
}
export class SubHasTipoHasEntCon {
  id: number
  submoduloHasTipo = new SubmoduloHasTipo
  entidadeConcedente = new Entidade
}
export class TipoRoteiro {
  id: number
  tipo: string
}
export class SubmoduloHasTipo {
  id: number
  submoduloRoteiro = new SubmoduloRoteiro
  tipoRoteiro = new TipoRoteiro
  aplicadoTudo;
  etapaPrevencao;
}
export class RoteiroParecer {
  id: number
  ultimaAtualizacao: Date
  status;
  versao: number
  usuario = new Usuario
  moduloParecer = new ModuloParecer
  submoduloRoteiro = new SubmoduloRoteiro
  tipoRoteiro = new TipoRoteiro
  secao;
}
export class Secao {
  id: number
  secao: string
  roteiroParecer = new RoteiroParecer
  questaoParecer = []
}
export class QuestaoParecer {
  id: number
  enunciado: string
  tipo;
  embasamentoLegal: string
  recomendacao: string
  continuar;
  informacaoesExtras: string
  tipoImpropriedade;
  secao = new Secao
  parecer: boolean
  escolha = []
  link: string
}
export class Escolha {
  id: number
  opcao: string
  questaoParecer = new QuestaoParecer
}
export class RespostaQuestaoParecer {
  id: number
  resposta: string
  responderData;
  observacao: string
  analise;
  anexo: string
  hashFile: string
  respostaEnum;
  questaoParecer = new QuestaoParecer
  responderRoteiro = new ResponderRoteiro
  data
  escolha = new Escolha
  usuario = new Usuario
}

export class ResponderRoteiro {
  id: number
  tituloAnalise
  processoFisico
  valor
  introducao
  conclusao
  data
  status;
  statusControleInterno
  roteiroParecer = new RoteiroParecer
  planoTrabalho = new PlanoTrabalho
  entidadeConcedente = new Entidade
  entidadeProponente = new Osc
  usuario = new Usuario
  usuarioSubmeteu = new Usuario
  hash
  situacao
  justificativaAlteracao
  recomendacaoAnalise
  flag = null
  etapaPrevencao

  celebracao;
  filtroTipoCelebracao;
  
  financeiro
}

export class SubmeterAnaliseControleInternoDTO {
  codigo: number
  status: number
  hash: string
  idUsuario: number
  recomendacaoAnalista: string
}

//MODELOS
export class ModelResponderRoteiro {
  modulo
  submodulo
  tipoRoteiro
  idProcesso
  campoProcesso
  status //antes, durante, depois e sempre
  idResponderRoteiro
}
export class ModelResponderRoteiroMultiplo {
  modulo
  submodulo
  tipoRoteiro
  idProcesso
  campoProcesso
  mesIni
  mesFim
  status //antes, durante, depois e sempre
}

export class ValidacaoCertidaoAreaPublica {
  entidadeProponente = new Osc()
  habilitacao = new Habilitacao()
  respostaQuestao = []
}

// MANIFESTAÇÃO DE INTERESSE

export class GerenciamentoPropostaPmis {
  id: number
  titulo: string
  informacoes: string
  dataIniProposta;
  dataFimProposta;
  dataIniOitivaProposta;
  dataFimOitivaProposta;
  dataFimParecerChamamento;
  manifestacaoChamamentoPublico;
  anexoManifestacao: string
  hashAnexoManifestacao: string
  entidadeConcedente = new Entidade
}

export class ResultadoPlanoTrabalho {
  id: number
  tipoResultadoPlano
  planoTrabalhoHasProponente
  nota
}

// ==== EXECUÇÃO HAS PARCERIA ==== //
export class Financeiro {
  id: number;
  obParcela: string;
  obDataTransf;
  obQtdParcela;
  obValorReceb;
  obAnexoManual: string;
  hashObAnexoManual: string;
  afDataIni;
  afDataFim;
  afValAplic;
  afValRend;
  devSaldoData;
  devSaldoValor;
  devAnexoComprovanteTransf: string;
  devHashAnexoComprovanteTransf: string;
  dataExtratoBancario;
  valorExtratoBancario;
  anexoExtratoBancario: string;
  hashAnexoExtratoBancario: string;
  tipo;
  categoria;
  idTipoDespesa;

  status;
  usuarioSolicitouAnalise;
	dataSolicitacaoAnalise;
	usuarioAvaliacao;
	dataAvaliacao;
  
  observacao;
  celebracao = new Celebracao;
  usuario = new Usuario;
}


export class DocumentoProjeto {
  id: number
  descricao: string
  recomendacao: string
  link: string
  ordem: number

  modulo: any
  fase: any
  aplicacao: any
  tipoCelebracao: any
  termosAplicados: any
  entidade: any

  respostaDocumento

  excluido
}

export class DocumentoProjetoPesquisaFiltro {
  modulo: any
  fase: any
  aplicacao: any
  tipoCelebracao: any
  termosAplicados: any
  entidade: any
}


export class RespostaDocumento {
  id: number

  status: string
  hashFile: string
  anexo: string
  justificativa: string
  envioJustificativa: any

  entidadeConcedente = new Entidade()
  entidadeProponente = new Osc()
  usuario = new Usuario()

  celebracao
  documentoProjeto
  dataEnvio

  excluido
}

// === INÍCIO MÓDULO DE AVALIAÇÃO DO PLANO === //
export class TipoAvaliacaoPlano {
  id: number;
  tipo: string;
  descricao: string;
  modelo;
  requisitoAvaliacaoPlano
}
export class RequisitoAvaliacaoPlano {
  id: number;
  requisito: string;
  tipoAvaliacaoPlano = new TipoAvaliacaoPlano();
  criterioAvaliacaoPlano
}
export class CriterioAvaliacaoPlano {
  id: number;
  criterio: string;
  detalhamento: string;
  peso: number;
  pontuacaoMax: number;
  criterioDesempate: number;
  requisitoAvaliacaoPlano = new RequisitoAvaliacaoPlano();
  flag
}
export class NotaCriterioAvaliacaoPlano {
  id: number;
  nota: any;
  observacao: string;
  tipo;
  usuarioAvaliaPlano = new UsuarioAvaliaPlano();
  criterioAvaliacaoPlano = new CriterioAvaliacaoPlano();
}
export class UsuarioAvaliaPlano {
  id: number;
  finalizadoPreliminar;
	finalizadoFinal;
	mediaPreliminar;
	mediaFinal;
	dataPreliminar;
	dataFinal;
	usuario = new Usuario()
  planoTrabalhoHasProponente = new PlanoTrabalhoHasProponente()
  recursoAnalisado
}
// === FIM MÓDULO DE AVALIAÇÃO DO PLANO === //

// === INÍCIO MÓDULO LINHA DO TEMPO === //
export class Conversa {
  id: number;
  dataConversaAberta: Date;
  dataConversaFechada: Date;
  tipo;
  tipohasEntidade;
  textoAuxProjeto: string;
  enumAuxProjeto;
  entidadeConcedente = new Entidade();
  entidadeProponente = new Osc();
  status;
  celebracao = new Celebracao();
}

export class Mensagem {
  id: number;
  dataEnvioMsg: any;
  mensagem: string;
  linkVideo: string;
  conversa = new Conversa();
  usuario = new Usuario();
}

export class AnexoMensagem {
  id: number;
  dataConversaAberta: Date;
  anexo: string;
  hashFile: string;
  mensagem = new Mensagem();
  usuario = new Usuario();
}

export class UsuarioHasMensagem {
  id: number;
  dataVisualizacao: Date;
  mensagem = new Mensagem();
  usuario = new Usuario();
}
// === FIM MÓDULO LINHA DO TEMPO === //

// === INÍCIO MÓDULO ALTERAR PLANO === //
export class VersaoPlano {
  id: number;
  versao: number;
  justificativa: string;
  dataSolicitacao: Date;
  dataAvaliacao: Date;
  planoTrabalhoHasProponente = new PlanoTrabalhoHasProponente;
  usuarioSolicitante = new Usuario;
  usuarioAvaliador = new Usuario;
  status;
  anexo;
  hashFile;
  tamanho;
}

export class AlterDescricaoRealidade {
  id: number;
  data;
  descricaoRealidadeAntes: string;
  descricaoRealidadeDepois: string;
  statusAlteracao;
  tipoAlteracao;
  justifAvalAlteracao: string;
  dataAvaliacao;
  usuarioAvaliou;
  usuarioSubmeteu;
  versaoPlano;
  descricaoRealidade;
  pthpId;
}

export class AlterCronogramaFisico {
  id: number;
  data;
  metaAntes: string;
  metaDepois: string;
  descricaoMetaAntes: string;
  descricaoMetaDepois: string;
  latitudeAntes;
  latitudeDepois;
  longitudeAntes;
  longitudeDepois;
  statusAlteracao;
  tipoAlteracao;
  justifAvalAlteracao: string;
  dataAvaliacao;
  usuarioAvaliou;
  usuarioSubmeteu;
  versaoPlano;
  cronogramaFisicoExecucaoObjeto;
  pthpId;
}

export class AlterEtapaCronograma {
  id: number;
  data;
  especificacaoAntes: string;
  especificacaoDepois: string;
  descricaoAntes: string;
  descricaoDepois: string;
  dataIniAntes;
  dataIniDepois;
  dataFimAntes;
  dataFimDepois;
  latitudeAntes;
  latitudeDepois;
  longitudeAntes;
  longitudeDepois;
  porcentagemExecutadaAntes;
  porcentagemExecutadaDepois;
  percentualEvidenciaAntes;
  percentualEvidenciaDepois;
  percentualDespesaAntes;
  percentualDespesaDepois;
  statusAlteracao;
  tipoAlteracao;
  justifAvalAlteracao: string;
  dataAvaliacao;
  etapaCronogramaFisico;
  usuarioAvaliou;
  usuarioSubmeteu;
  versaoPlano;
  cronogramaFisicoExecucaoObjeto;
  pthpId;
}

export class AlterDetalhamentoFinanceiro {
  id: number;
  data;
  codigoAntes: string;
  codigoDepois: string;
  especificacaoAntes: string;
  especificacaoDepois: string;
  totalAntes;
  totalDepois;
  tipo;
  statusAlteracao;
  tipoAlteracao;
  justifAvalAlteracao: string;
  dataAvaliacao;
  detalhamentoFinanceiro;
  usuarioAvaliou;
  usuarioSubmeteu;
  versaoPlano;
  categoria;
  pthpId;
}

export class AlterDetalhamentoHasEtapa {
  id: number;
  data;
  especificacaoAntes: string;
  especificacaoDepois: string;
  unAntes: string;
  unDepois: string;
  funcaoAntes: string;
  funcaoDepois: string;
  mesAntes;
  mesDepois;
  mesAnoContratacaoAntes;
  mesAnoContratacaoDepois;
  mesAnoDispensaAntes;
  mesAnoDispensaDepois;
  quantidadeAntes;
  quantidadeDepois;
  salarioAntes;
  salarioDepois;
  inssAntes;
  inssDepois;
  terceiroAntes;
  terceiroDepois;
  fgtsAntes;
  fgtsDepois;
  pisAntes;
  pisDepois;
  umDozeDeFeriasAntes;
  umDozeDeFeriasDepois;
  umTresDeFeriasAntes;
  umTresDeFeriasDepois;
  umDozeDeTrezeSalarioAntes;
  umDozeDeTrezeSalarioDepois;
  multaRecisoriaAntes;
  multaRecisoriaDepois;
  encargoMensalAntes;
  encargoMensalDepois;
  valorUnitarioAntes;
  valorUnitarioDepois;
  totalUnitarioAntes;
  totalUnitarioDepois;
  valorTotalAntes;
  valorTotalDepois;
  nomeEtapaAntes: string;
  nomeEtapaDepois: string;
  equipamentoMaterialPermanenteAntes;
  equipamentoMaterialPermanenteDepois;
  statusAlteracao;
  tipoAlteracao;
  justifAvalAlteracao: string;
  dataAvaliacao;
  detalhamentoHasEtapa;
  detalhamentoFinanceiro;
  etapaCronogramaFisico;
  usuarioAvaliou;
  usuarioSubmeteu;
  versaoPlano;
  pthpId;

}

export class AlterSinteseProposta {
  id: number;
  data;
  tituloPropostaAntes;
  tituloPropostaDepois;
  identificacaoObjetoAntes;
  identificacaoObjetoDepois;
  objetivoGeralPropostaAntes;
  objetivoGeralPropostaDepois;
  objetivoEspecificoPropostaAntes;
  objetivoEspecificoPropostaDepois;
  justificativaPropostaAntes;
  justificativaPropostaDepois;
  abrangenciaPropostaAntes;
  abrangenciaPropostaDepois;
  publicoBeneficiarioAntes;
  publicoBeneficiarioDepois;
  perfilPublicoBeneficiarioAntes;
  perfilPublicoBeneficiarioDepois;
  metaAtendimentoAntes;
  metaAtendimentoDepois;
  dataIniExecucaoAntes;
  dataIniExecucaoDepois;
  dataFimExecucaoAntes;
  dataFimExecucaoDepois;
  metodologiaAbordagemPropostaAntes;
  metodologiaAbordagemPropostaDepois;
  statusAlteracao;
  tipoAlteracao;
  justifAvalAlteracao: string;
  dataAvaliacao;
  usuarioAvaliou;
  usuarioSubmeteu;
  versaoPlano;
  sinteseProposta;
  pthpId;
}

export class AlterMonitoramentoAvaliacao {
  id: number;
  data;
  tecnicaMonitoramentoAntes;
  tecnicaMonitoramentoDepois;
  sustentabilidadePropostaAntes;
  sustentabilidadePropostaDepois;
  statusAlteracao;
  tipoAlteracao;
  justifAvalAlteracao: string;
  dataAvaliacao;
  usuarioAvaliou;
  usuarioSubmeteu;
  versaoPlano;
  monitoramentoAvaliacao;
  pthpId;
}
// === FIM MÓDULO ALTERAR PLANO === //

// === MÓDULO NOTIFICACAO === //
export class Notificacao {
  id: number;
  titulo: string;
  conteudo: string;
  tipoNotificacao;
  prioridade;
  usuarioRemetente = new Usuario();
  anexosNotificacao = [];
  dataCadastro
}

export class AnexoNotificacao {
  id: number;
  anexo;
  hashFile;
  tamanho;
  notificacao = new Notificacao();
}

export class NotificacaoHasUsuario {
  id: number;
  dataLeitura;
  dataEnvio;
  statusNotificacao;
  lixeira;
  favorito;
  usuario = new Usuario();
  notificacao = new Notificacao();
}

export class NotificacaoHasUsuarioDTO {
  dataEnvio;
  statusNotificacao;
  favorito;
  lixeira;
  usuarioLista;
  notificacao = new Notificacao();
}
// === FIM MÓDULO NOTIFICACAO === //

// === MÓDULO ATENDIDOS === //
export class AtendidosProjeto {
  id: number;
  nome: string;

  email: string;
  nomePai: string;
  nomeMae: string;
  dataNascimento;
  sexo;
  estadoCivil;
  naturalidade: string;
  escolaridade;
  profissao;
  cpf;
  rg;
  endereco;
  bairro;
  cidade;
  estado;
  cep;
  telefoneResidencial;
  telefoneComercial;
  celular;
  celularWhats;
  usuarioCadastrou = new Usuario();
  ultimaAtualizacao;
  numeroDependentes;
	idade;
	quilombola;
	indigena;
	cadUnico;
	planoTrabalhoHasProponente;
}
export class CelebracaoHasAtendidosProjeto {
  id: number;
  linkArquivo: string;
  celebracao;
  atendidosProjeto = new AtendidosProjeto();
  arquivo
  hashFile
  status
  usuarioCadastroArquivo = new Usuario();
  dataCadastroArquivo
  usuarioCadastroVinculo = new Usuario();
  dataCadastroVinculo
}
// === FIM MÓDULO ATENDIDOS === //


export class Bens {
  id: number;
  tipo
  situacao
  especificacaoBem: string;
  qtd: number;
  valorUnit: number;
  valorTotal: number;
  dataCadastro: Date;
  dataDevolucaoDoacao: Date;
  detalhamento: string;
  comprovanteRecibo
  hashComprovanteRecibo
  detFinHasEtCronFis
  usuarioCadastro = new Usuario();
  usuarioRecebimento = new Usuario();

  excluido
}

export class AlteracoesExecucao{
  id;
  tipo;
  situacao;
	justificativa;
	aprovacaoGestor;
	recomendacao;
	usuarioSolicitante = new Usuario()
	dataCadastro;
	usuarioResponsavelAprovacao= new Usuario()
	dataAprovacao;
	qtdDias;
	periodoAnterior;
	periodoPosterior;
	remanejamentoClausula;
	hashRemanejamentoClausula;
	situacaoHabilitacao;
	celebracao;
}

export class OrcamentoClassQualitativa{

}

export class OrcamentoClassQualitativaProduto{
  
}

// === FINANCEIRO === //
export class ClassContabil{
  id;
	codigo;
	descricao;
}

export class TipoDespesaReceita{
  id;
	codigo;
	descricao;
	tipo;
}

export class ContaBancaria{
  id;
	descricao;
	restricao;
	valor;
	banco;
	ag;
	conta;
	tipoConta;
	dataAbertura;
	entidadeProponente = new Entidade();
	planoTrabalhoHasProponente = new PlanoTrabalhoHasProponente();
}

export class ItemPlanoContas{
  id;
	tituloItem;
	tipoConta;
	itemPlanoContas //= new ItemPlanoContas();
	tipoDespesaReceita = new TipoDespesaReceita() ;
	classContabil = new ClassContabil();
}

export class Extrato{
  id;
	infoextrato;
	dataCadastro;
	dataFatoMovimentacao;
	valorOp;
	dataOp;
	contaBancaria = new ContaBancaria();
	usuario = new Usuario();
	tipoDespesaReceita = new TipoDespesaReceita()
  itemPlanoContas = null //= new ItemPlanoContas()
  contaDaTransferencia: ContaBancaria = null;
  extrato;
  despesaId;
  pagamentoPessoalId;
  planoTrabalhoHasProponenteId;
  financeiroId;
  recursoFinanceiro;
  anexo;
  anexoHash;
  anexoTamanho;
  excluido;
}

export class Transferencia {
  id;
	extratoDebitado;
	extratoCreditado;
}

export class AnaliseRepasse {
  id;
	repasseId;
	planoTrabalhoHasProponenteId;
  usuarioId;
  nomeAnaliseRepasse;
  observacao;
  anexo;
  hashAnexo;
  status;
  dataCadastro;
  excluido;
}

export class DataUpload {
  destination;
  encoding;
  fieldname;
  filename;
  mimetype;
  originalname;
  path;
  size;
}